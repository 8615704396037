<style scoped>
.logo-img-wrap {
	height: 54px !important;
	padding-top: 0px;
	padding-right: 26px;
	padding-bottom: 0px;
	padding-left: 10px;
}
.portal-header {
	font-weight: 600;
}
</style>
<template>
	<div>
		<!-- BEGIN #header -->
		<div id="header" class="app-header" v-bind:class="{ 'app-header-inverse': appOptions.appHeaderInverse }">
			<!-- BEGIN navbar-header -->
			<div class="navbar-header">
				<button type="button" class="navbar-mobile-toggler" v-on:click="toggleSidebarEndMobile" v-if="appOptions.appSidebarTwo">
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>

				<router-link to="/" class="navbar-brand logo-img-wrap">
					<b-img :src="require('./../../assets/logotransparent-black-text.png')" class="img-fluid logo-img-wrap"></b-img>
					<!--<span class="portal-header">Customer Portal</span>-->
				</router-link>

				<button type="button" class="navbar-mobile-toggler" v-on:click="toggleTopMenuMobile" v-if="appOptions.appTopMenu && !appOptions.appSidebarNone">
					<span class="fa-stack fa-lg text-inverse">
						<i class="far fa-square fa-stack-2x"></i>
						<i class="fa fa-cog fa-stack-1x"></i>
					</span>
				</button>
				<button type="button" class="navbar-mobile-toggler" v-on:click="toggleTopMenuMobile" v-if="appOptions.appTopMenu && appOptions.appSidebarNone">
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
				<button type="button" class="navbar-mobile-toggler" v-on:click="toggleHeaderMegaMenuMobile" v-if="appOptions.appHeaderMegaMenu">
					<span class="fa-stack fa-lg text-inverse m-t-2">
						<i class="far fa-square fa-stack-2x"></i>
						<i class="fa fa-cog fa-stack-1x"></i>
					</span>
				</button>
				<button type="button" class="navbar-mobile-toggler" v-on:click="toggleSidebarMobile" v-if="!appOptions.appSidebarNone">
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
			</div>
			<!-- END navbar-header -->
			<!-- BEGIN header-nav -->
			<div class="navbar-nav">
				<b-nav-item-dropdown :style="isXsSm ? '' : 'display: flex;flex: auto;'" v-if="this.$store.state.auth.clientPermission && this.$store.state.auth.clientPermission.length > 1" class="navbar-item" toggle-class="navbar-link dropdown-toggle" no-caret>
					<template slot="button-content">
						<span class="name d-none d-sm-inline me-1" style="color: black">{{ this['auth/chosenClientPermission'].businessName }}</span> <b class="caret"></b>
					</template>
					<b-dropdown-item v-for="(clientPermission, idx) in this.$store.state.auth.clientPermission" :key="idx" @click="handleSelectClientPermission(clientPermission)">{{clientPermission.businessName}}</b-dropdown-item>
				</b-nav-item-dropdown>
				<header-mega-menu v-if="appOptions.appHeaderMegaMenu"></header-mega-menu>
				<b-nav-item-dropdown right menu-class="me-1" class="navbar-item navbar-user dropdown" toggle-class="navbar-link dropdown-toggle d-flex align-items-center" no-caret>
					<template slot="button-content">
						<div class="image image-icon bg-gray-800 text-gray-600">
							<i class="fa fa-user"></i>
						</div>
						<span class="d-none d-md-inline">{{ this.$store.state.auth.firstName }} {{ this.$store.state.auth.lastName }}</span> <b class="caret"></b>
					</template>
					<a @click="changePassword" class="dropdown-item">Change Password</a>
					<div class="dropdown-divider"></div>
					<a class="dropdown-item" @click="logout">Log Out</a>
				</b-nav-item-dropdown>
				<div class="navbar-divider d-none d-md-block" v-if="appOptions.appSidebarTwo"></div>
				<div class="navbar-item d-none d-md-block" v-if="appOptions.appSidebarTwo">
					<a href="javascript:;" v-on:click="toggleSidebarEnd" class="navbar-link icon">
						<i class="fa fa-th"></i>
					</a>
				</div>
			</div>
			<!-- end header navigation right -->
		</div>
		<div v-b-visible="handleVisible" class="position-fixed d-block d-md-none" style="z-index: 20000; height: 1px;"></div>
		<!-- end #header -->
	</div>
</template>

<script>
import AppOptions from "../../config/AppOptions.vue";
import HeaderMegaMenu from "./HeaderMegaMenu.vue";

import { mapGetters } from "vuex";

export default {
	name: "Header",
	components: {
		HeaderMegaMenu,
	},
	data() {
		return {
			appOptions: AppOptions,
			isXsSm: false,
		};
	},
	computed: {
		...mapGetters(["auth/chosenClientPermission"]),
	},
	methods: {
		handleVisible(isVisible) {
			this.isXsSm = isVisible;
		},
		logout() {
			this.$router.push("/logout");
		},
		changePassword() {
			this.$router.push("/changepassword").catch(() => {
				//Prevent console logging Navigation Duplicate error if already on /changepassword
			});
		},
		handleSelectClientPermission(clientPermission) {
			this.$store.commit("auth/changeClient", clientPermission);
		},
		toggleSidebarMobile() {
			this.appOptions.appSidebarMobileToggled =
				!this.appOptions.appSidebarMobileToggled;
		},
		toggleSidebarEnd() {
			this.appOptions.appSidebarEndToggled =
				!this.appOptions.appSidebarEndToggled;
		},
		toggleSidebarEndMobile() {
			this.appOptions.appSidebarEndMobileToggled =
				!this.appOptions.appSidebarEndMobileToggled;
		},
		toggleTopMenuMobile() {
			this.appOptions.appTopMenuMobileToggled =
				!this.appOptions.appTopMenuMobileToggled;
		},
		toggleHeaderMegaMenuMobile() {
			this.appOptions.appHeaderMegaMenuMobileToggled =
				!this.appOptions.appHeaderMegaMenuMobileToggled;
		},
		checkForm: function (e) {
			e.preventDefault();
			this.$router.push({ path: "/extra/search" });
		},
	},
};
</script>
