<style scoped>
* {
  font-size: 14px !important;
}
.panel /deep/ .panel-heading .panel-title {
  font-size: 14px !important;
}
</style>
<template>
  <div>
    <div class="row">
      <!-- begin col-6 -->
      <div class="col-xl-12">
        <!-- begin panel -->
        <panel title="Quote List">
          <div class="panel-body">
            <list-skeleton v-if="loadingGetRequest" />
            <vue-good-table
              v-else
              :columns="quoteColumns"
              :rows="quoteRows"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: numberOfRowsDisplayed,
              }"
            >
              <template slot="pagination-bottom" slot-scope="props">
                <custom-pagination
                  :total="props.total"
                  :pageChanged="props.pageChanged"
                  :perPageChanged="props.perPageChanged"
                >
                </custom-pagination>
              </template>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                  <a
                    class="btn btn-primary btn-sm"
                    v-on:click="viewQuote(props.row._id)"
                    >View</a
                  >
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </panel>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import { mapGetters } from "vuex";
import CustomPagination from "./../../components/CustomPagination.vue";
import ListSkeleton from "../../components/ListSkeleton.vue";

export default {
  name: "QuotesInventoryView",
  components: {
    CustomPagination,
    ListSkeleton,
  },
  data() {
    return {
      quoteColumns: [
        {
          label: "Quote Number",
          field: "quoteNumber",
        },
        {
          label: "Title",
          field: "title",
        },
        {
          label: "Summary",
          field: "summary",
        },
        {
          label: "Terms",
          field: "terms",
        },
        {
          label: "Date",
          field: "date",
        },
        {
          label: "Total",
          field: "total",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "",
          field: "action",
        },
      ],
      quoteRows: [],
      loadingGetRequest: true,
    };
  },
  computed: {
    ...mapGetters(["numberOfRowsDisplayed"]),
    chosenClient: function () {
      return this.$store.state.auth.chosenClientPermission;
    },
  },
  watch: {
    chosenClient() {
      this.getData();
    },
  },
  methods: {
    viewQuote(id) {
      this.$router.push(`/billing/quotes/${id}`);
    },
    getData() {
      this.loadingGetRequest = true;

      this.$http.get("/client/v1/quote").then((response) => {
        this.quoteRows = response.data.data.quotes.map((x) => {
          x.date = format(new Date(x.date), "MM/dd/yyyy");
          return x;
        });
        this.loadingGetRequest = false;
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>
