<template>
	<div>
	</div>
</template>

<script>
import AppOptions from "../../config/AppOptions.vue";

export default {
	name: "PasswordReset",
	props: {
		token: String,
	},
	created() {
		AppOptions.appEmpty = true;
	},
	mounted: function () {
		this.$http
			.post("/client/v1/user/impersonate", {
				token: this.token,
			})
			.then((response) => {
				if (response.data.status == "success") {
					this.$store.commit("auth/auth_success", {
						token: this.token,
						emailAddress: response.data.data.emailAddress,
						firstName: response.data.data.firstName,
						lastName: response.data.data.lastName,
						clientPermission: response.data.data.clientPermission,
						accountLevel: response.data.data.accountLevel,
					});
					this.$router.push("/home");
				} else {
					this.$router.push("/login");
				}
			});
	},
	beforeRouteLeave(to, from, next) {
		AppOptions.appEmpty = false;
		next();
	},
	data() {
		return {};
	},
	methods: {},
};
</script>