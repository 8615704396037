<style scoped>
.add-user-input /deep/ .vs__dropdown-toggle {
	height: 45px;
}
.add-user-input /deep/ .vs__dropdown-toggle .vs__selected-options .vs__search {
	height: 45px;
}
.add-button-container {
	display: flex;
	justify-content: center;
}
.add-button {
	min-width: 200px;
}
.add-form-group {
	display: flex;
	align-items: center;
}
.business-label {
	font-weight: 600;
	font-size: 16px;
}
.remove-icons /deep/ .panel-heading .panel-heading-btn {
	display: none;
}
.add-form-container {
	width: 600px;
}
.container-container {
	display: flex;
	justify-content: center;
}
.select-invalid {
	border-bottom: 1px solid #ff5b57 !important;
	border-top: 1px solid #ff5b57 !important;
	border-left: 1px solid #ff5b57 !important;
	border-right: 1px solid #ff5b57 !important;
	border-radius: 6px;
}
.error-message {
	color: #ff5b57;
}
</style>
<template>
	<div>
		<ol class="breadcrumb pull-right">
			<li class="breadcrumb-item"><a>Home</a></li>
			<li class="breadcrumb-item"><a>Users</a></li>
			<li class="breadcrumb-item active">
				<a>Add</a>
			</li>
		</ol>

		<div class="row" style="margin-top: 36px;">
			<div class="col-xl-6 offset-xl-3">
				<panel title="Add New User" class="remove-icons">
					<form @submit.prevent="submitForm">
						<div class="container-container">
							<div class="add-form-container">
								<div class="form-floating mb-20px mt-4">
									<input class="form-control fs-13px h-45px" :class="{'is-invalid': v$.form.firstName.$error}" placeholder="First Name" id="firstName" name="firstName" type="input" v-model="form.firstName">
									<label for="firstName" class="d-flex align-items-center text-gray-600 fs-13px">First Name</label>
								</div>
								<div class="form-floating mb-20px">
									<input class="form-control fs-13px h-45px" :class="{'is-invalid': v$.form.lastName.$error}" placeholder="Last Name" id="lastName" name="lastName" type="input" v-model="form.lastName">
									<label for="lastName" class="d-flex align-items-center text-gray-600 fs-13px">Last Name</label>
								</div>
								<div class="form-floating">
									<input class="form-control fs-13px h-45px" :class="{'is-invalid': v$.form.emailAddress.$error}" placeholder="Email Address" id="emailAddress" name="emailAddress" type="input" v-model="form.emailAddress">
									<label for="emailAddress" class="d-flex align-items-center text-gray-600 fs-13px">Email Address</label>

								</div>
								<p class="error-message" v-if="v$.$errors.find(x => x.$validator === 'validateEmail')">
									Invalid Email
								</p>
								<div v-if="!oneClientPermission" class="form-group row m-b-15 mt-4 add-form-group">
									<div class="business-label mb-2">Client Permissions:</div>
									<br>
									<div class="form-floating mb-20px">
										<v-select class="add-user-input" :class="{'select-invalid': v$.form.clientPermission.$error}" multiple v-model="form.clientPermission" :options="clients" label="businessName" :reduce="client => client._id" />
									</div>
								</div>
								<div v-else class="form-group row m-b-15 mt-4 add-form-group">
									<label class="col-form-label col-md-3">Client Permissions:</label>
									<div class="col-md-9 business-label">
										{{clients[0].businessName}}
									</div>
								</div>
								<div class="login-buttons add-button-container mt-4">
									<button class="btn btn-success btn-block btn-lg add-button"> Add </button>
								</div>
							</div>
						</div>
					</form>
					<b-alert :show="alert.show" :variant="alert.variant" class="mt-4" style="text-align: center; font-size:16px;">{{alert.message}}</b-alert>
				</panel>
			</div>
		</div>
	</div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

const isGreaterThanZero = (value) => {
	return value.length > 0;
};

const validateEmail = (email) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
};

export default {
	name: "AddUser",
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			form: {
				emailAddress: "",
				clientPermission: [],
				firstName: "",
				lastName: "",
			},
			clients: [],
			oneClientPermission: false,
			alert: {
				show: false,
				message: "",
				variant: "danger",
			},
			loadingPostRequest: false,
		};
	},
	validations() {
		let ret = {};
		ret.form = {
			emailAddress: {
				required,
				validateEmail: helpers.withMessage(
					"Invalid Email",
					validateEmail
				),
			},
			firstName: { required },
			lastName: { required },
			clientPermission: { isGreaterThanZero },
		};

		return ret;
	},
	methods: {
		status(validation) {
			return {
				error: validation.$error,
				dirty: validation.$dirty,
			};
		},
		async validateForm() {
			this.alert.show = false;
			const vueValidation = await this.v$.$validate();

			if (!vueValidation) {
				this.alert.message =
					"Complete the required field(s) to sign in.";
				this.alert.variant = "danger";
				this.alert.show = true;
				return false;
			} else {
				return true;
			}
		},
		async submitForm() {
			const validForm = await this.validateForm();
			if (!validForm) {
				return;
			}
			this.loadingPostRequest = true;
			this.$http
				.post(`/client/v1/user/add`, this.form)
				.then((response) => {
					if (response.data.status === "success") {
						this.$router.push("/user/list");
					}
				})
				.finally(() => {
					this.loadingPostRequest = false;
				});
		},
	},
	created() {
		this.clients = this.$store.state.auth.clientPermission;

		if (this.clients.length === 1) {
			this.form.clientPermission.push(this.clients[0]._id);
			this.oneClientPermission = true;
		}
	},
};
</script>