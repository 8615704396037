<style scoped>
.li-background-color {
	background-color: #dee2e6;
}

.main-col-text-desktop {
	text-align: end;
}

.main-col-text-mobile {
	text-align: center;
}

.img-mobile-col {
	text-align: center;
}
.li-card-mobile {
	margin: top 64px;
}

.li-main-div {
	display: flex;
	align-items: center;
}

h1,
.h1 {
	font-size: 2.875rem;
}
</style>
<template>
	<div class="h-100 li-main-div">
		<b-row class="mx-auto justify-content-center">
			<b-card class="mx-auto li-background-color border-0 d-none d-md-block">
				<b-row>
					<b-col class="main-col-text-desktop my-auto" md="6">
						<h1>Oops!</h1>
						<h3>Page not found.</h3>
					</b-col>
					<b-col md="6">
						<b-img class="img-fluid" :src="require('./../assets/img/li-tech-frown.jpg')" />
					</b-col>
				</b-row>
			</b-card>
			<b-card class="mx-auto li-background-color border-0 d-md-none li-card-mobile mt-4">
				<b-row>
					<b-col cols="12" class="main-col-text-mobile my-auto">
						<h1>Oops!</h1>
						<h3>Page not found.</h3>
					</b-col>
					<b-col cols="12" class="img-mobile-col">
						<b-img class="img-fluid" :src="require('./../assets/img/li-tech-frown.jpg')" />
					</b-col>
				</b-row>
			</b-card>
		</b-row>
	</div>
</template>
<script>
import AppOptions from "../config/AppOptions.vue";

export default {
	name: "NotFound",
	created() {
		AppOptions.appEmpty = true;
	},
	beforeRouteLeave(to, from, next) {
		AppOptions.appEmpty = false;
		next();
	},
	data() {
		return {};
	},
	methods: {
		getImgUrl() {
			var images = require.context("../assets/", false, /\.jpg$/);
			return images("./" + "sad-boi" + ".jpg");
		},
	},
};
</script>