const state = {
  nRows: 10,
};

const getters = {
  numberOfRowsDisplayed: (state) => state.nRows,
};

const actions = {
  changeNumberOfRows({ commit }, rows) {
    commit("setNRows", rows);
  },
};

const mutations = {
  setNRows: (state, rows) => (state.nRows = rows),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
