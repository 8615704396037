<template>
	<div>
		<!-- BEGIN login -->
		<div class="login login-v2 fw-bold">
			<!-- BEGIN login-cover -->
			<div class="login-cover">
				<div class="login-cover-img" v-bind:style="{ backgroundImage: 'url(' + bg.activeImg + ')' }"></div>
				<div class="login-cover-bg"></div>
			</div>
			<!-- END login-cover -->

			<!-- BEGIN login-container -->
			<div class="login-container">
				<div>
					<div>
						<b-img :src="require('./../../assets/logotransparent.png')" class="img-fluid"></b-img>
					</div>
					<div class="mt-4 mb-2">
						<h2>Customer Portal</h2>
					</div>
				</div>

				<!-- BEGIN login-content -->
				<div class="login-content">
					<form v-on:submit.prevent>
						<div class="form-floating mb-20px" v-if="!isAskingForToken">
							<input
								type="text"
								class="form-control fs-13px h-45px"
								:class="{
									'is-invalid': v$.form.email.$error,
									'border-0': !v$.form.email.$error,
								}"
								placeholder="Email Address"
								id="emailAddress"
								v-model="form.email"
								v-on:keyup.enter="signIn"
							/>
							<label for="emailAddress" class="d-flex align-items-center text-gray-600 fs-13px">Email Address</label>
						</div>
						<div class="form-floating mb-20px" v-if="isAskingForToken">
							<h4>
								If your email exists in our system we will send you a one time login link momentarily. Please check your email. It may take up
								to 5 minutes to arrive.
							</h4>
						</div>
						<div class="mb-20px" v-if="!isAskingForToken">
							<!-- another here -->
							<button class="btn btn-success d-block w-100 h-45px btn-lg" @click="signIn">
								<b-spinner v-if="loadingPostRequest" label="Spinning"></b-spinner>
								<div v-else>Sign me in</div>
							</button>
						</div>
					</form>
					<b-alert :show="alert.show" :variant="alert.variant" style="text-align: center">{{ alert.message }}</b-alert>
				</div>
				<!-- END login-content -->
			</div>
			<!-- END login-container -->
		</div>
		<!-- END login -->
	</div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import AppOptions from "../../config/AppOptions.vue";

export default {
	name: "Login",
	props: {
		token: String,
	},
	setup() {
		return { v$: useVuelidate() };
	},
	beforeCreate() {
		AppOptions.appEmpty = true;
		this.$fingerprint
			.then((fp) => fp.get())
			.then((result) => {
				this.form.visitorID = result.visitorId;
			});
	},
	beforeRouteLeave(to, from, next) {
		AppOptions.appEmpty = false;
		next();
	},
	mounted: function () {
		console.log(` LOGIN TOKEN : ${this.token}`);
		if (this.token) {
			this.$http
				.post("/client/v2/user/checkMagicLink", {
					token: this.token,
					rememberMe: true,
					//visitorID: result.visitorId,
				})
				.then((response) => {
					if (response.data.status == "success") {
						this.$store.commit("auth/auth_success", response.data.data);
						this.$router.push("/home");
					} else {
						this.alert.message = response.data.message.content;
						this.alert.variant = "danger";
						this.alert.show = true;
						this.loadingPostRequest = false;
					}
				});
		}
		/*if (this.token) {
			this.$fingerprint.then((fp) => {
				console.log();
				// todo fucking fix, why would this not work?
				fp.get().then((result) => {
					this.$http
						.post("/client/v2/user/checkMagicLink", {
							token: this.token,
							rememberMe: true,
							visitorID: result.visitorId,
						})
						.then((response) => {
							if (response.data.status == "success") {
								this.$store.commit("auth/auth_success", response.data.data);
								this.$router.push("/home");
							} else {
								this.alert.message = response.data.message.content;
								this.alert.variant = "danger";
								this.alert.show = true;
								this.loadingPostRequest = false;
							}
						});
				});
			});
		}*/
	},
	data() {
		return {
			bg: {
				activeImg: "/assets/img/login-bg/login-bg-17.jpg",
			},
			form: {
				email: "",
				rememberMe: true,
				visitorID: "",
			},
			isAskingForToken: false,
			loadingPostRequest: false,
			alert: {
				show: false,
				message: "",
				variant: "danger",
			},
		};
	},
	validations() {
		let ret = {};
		if (this.isAskingForToken) {
			ret.form = {
				token: { required },
			};
		} else {
			ret.form = {
				email: { required },
			};
		}
		return ret;
	},
	methods: {
		async validateForm() {
			this.alert.show = false;
			const vueValidation = await this.v$.$validate();

			if (!vueValidation) {
				this.alert.message = "Complete the required field(s) to sign in.";
				this.alert.variant = "danger";
				this.alert.show = true;
				return false;
			} else {
				return true;
			}
		},
		async signIn() {
			if (!this.loadingPostRequest) {
				this.loadingPostRequest = true;
				const validForm = await this.validateForm();
				if (!validForm) {
					this.loadingPostRequest = false;
					return;
				}

				let postData = {
					email_address: this.form.email,
				};

				this.$http
					.post("/client/v2/user/getMagicLink", postData)
					.then((response) => {
						if (response.data.status === "success") {
							this.isAskingForToken = true;
						} else {
							this.alert.message = response.data.message.content;
							this.alert.variant = "danger";
							this.alert.show = true;
							this.loadingPostRequest = false;
						}
					})
					.catch(() => {
						//general request failure
						this.alert.message = "Something went wrong.";
						this.alert.variant = "danger";
						this.alert.show = true;
					})
					.finally(() => {
						this.loadingPostRequest = false;
					});
			}
		},
	},
};
</script>
