<template>
	<div>
		<!-- BEGIN login -->
		<div class="login login-v2 fw-bold">
			<!-- BEGIN login-cover -->
			<div class="login-cover">
				<div class="login-cover-img" v-bind:style="{ backgroundImage: 'url('+ bg.activeImg +')' }"></div>
				<div class="login-cover-bg"></div>
			</div>
			<!-- END login-cover -->

			<!-- BEGIN login-container -->
			<div class="login-container">
				<!-- BEGIN login-header -->
				<div class="login-header">
					<div class="brand">
						<div class="d-flex align-items-center">
							<span class="logo"></span> <b>LITech</b> Portal
						</div>
						<small>Please sign in</small>
					</div>
					<div class="icon">
						<i class="fa fa-lock"></i>
					</div>
				</div>
				<!-- END login-header -->

				<!-- BEGIN login-content -->
				<div class="login-content">
					<form v-on:submit.prevent>
						<div class="form-floating mb-20px">
							<input type="password" class="form-control fs-13px h-45px" :class="{'is-invalid': v$.form.password.$error, 'border-0': !v$.form.password.$error}" placeholder="Password" id="password" v-model="form.password" v-on:keyup.enter="changePassword" />
							<label for="password" class="d-flex align-items-center text-gray-600 fs-13px">New Password</label>
						</div>
						<div class="form-floating mb-20px">
							<input type="password" class="form-control fs-13px h-45px" :class="{'is-invalid': v$.form.confirm_password.$error, 'border-0': !v$.form.confirm_password.$error}" placeholder="Password" id="password" v-model="form.confirm_password" v-on:keyup.enter="changePassword" />
							<label for="password" class="d-flex align-items-center text-gray-600 fs-13px">Confirm New Password</label>
						</div>
						<div class="mb-20px">
							<button class="btn btn-success d-block w-100 h-45px btn-lg" @click="changePassword">
								<b-spinner v-if="loadingPostRequest" label="Spinning"></b-spinner>
								<div v-else>Confirm New Password</div>
							</button>
						</div>
					</form>
					<b-alert v-if="displayPasswordRules" show variant="info" style="font-size:14px;">
						Password Requirements:
						<ul style="list-style-type:none;">
							<li><i :class="v$.form.password.minLength.$invalid || v$.form.password.required.$invalid ? iconClasses.fail : iconClasses.pass" /> Minimum 12 characters</li>
							<li><i :class="v$.form.password.includesOneLowercase.$invalid ? iconClasses.fail : iconClasses.pass" /> At least one lowercase letter</li>
							<li><i :class="v$.form.password.includesOneUppercase.$invalid ? iconClasses.fail : iconClasses.pass" /> At least one uppercase letter</li>
							<li><i :class="v$.form.password.includesOneDigit.$invalid ? iconClasses.fail : iconClasses.pass" /> At least one number</li>
							<li><i :class="v$.form.password.includesOneSymbol.$invalid ? iconClasses.fail : iconClasses.pass" /> At least one symbol</li>
						</ul>
						Confirm Password Requirement:
						<ul style="list-style-type:none;">
							<li><i :class="v$.form.confirm_password.sameAsPassword.$invalid || v$.form.confirm_password.required.$invalid ? iconClasses.fail : iconClasses.pass" />Matches Password</li>
						</ul>
					</b-alert>
					<b-alert v-else :show="alert.show" :variant="alert.variant" style="text-align:center;font-size:14px;">{{alert.message}}</b-alert>
				</div>
				<!-- END login-content -->
			</div>
			<!-- END login-container -->
		</div>
		<!-- END login -->
	</div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";

import AppOptions from "../../config/AppOptions.vue";

const includesOneLowercase = (value) => {
	return value.toUpperCase() != value;
};

const includesOneUppercase = (value) => {
	return value.toLowerCase() != value;
};

const includesOneDigit = (value) => {
	const isValid = /\d/.test(value);
	return isValid;
};

const includesOneSymbol = (value) => {
	const isValid = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value); //  /^[@$!%*?&]+/g.test(value);
	return isValid;
};

export default {
	name: "PasswordReset",
	setup() {
		return { v$: useVuelidate() };
	},
	props: {
		id: String,
	},
	created() {
		AppOptions.appEmpty = true;
		this.$fingerprint
			.then((fp) => fp.get())
			.then((result) => {
				this.form.visitorID = result.visitorId;
			});
	},
	beforeRouteLeave(to, from, next) {
		AppOptions.appEmpty = false;
		next();
	},
	data() {
		return {
			bg: {
				activeImg: "/assets/img/login-bg/login-bg-17.jpg",
			},
			iconClasses: {
				pass: "fas fa-check-circle text-success mx-2",
				fail: "fas fa-times-circle text-danger mx-2",
			},
			form: {
				password: "",
				confirm_password: "",
				visitorID: "",
			},
			displayPasswordRules: false,
			alert: {
				show: false,
				message: "",
				variant: "danger",
			},
			loadingPostRequest: false,
		};
	},
	validations() {
		return {
			form: {
				password: {
					required,
					minLength: minLength(12),
					includesOneLowercase,
					includesOneUppercase,
					includesOneDigit,
					includesOneSymbol,
				},
				confirm_password: {
					required,
					sameAsPassword: sameAs(this.form.password),
				},
			},
		};
	},
	methods: {
		async validateForm() {
			this.alert.show = false;
			const vueValidation = await this.v$.$validate();
			this.displayPasswordRules =
				this.v$.form.password.$invalid ||
				this.v$.form.confirm_password.$invalid;

			return vueValidation;
		},
		async changePassword() {
			if (!this.loadingPostRequest) {
				this.loadingPostRequest = true;
				const validForm = await this.validateForm();
				if (!validForm) {
					this.loadingPostRequest = false;
					return;
				}

				let postData = {
					new_password: this.form.password,
					id: this.id,
				};

				this.$http
					.post("/client/v1/user/resetPassword", postData)
					.then((response) => {
						if (response.data.status === "success") {
							this.$router.push("/login"); // and show some success data?
						} else {
							this.alert.message = response.data.message.content;
							this.alert.variant = "danger";
							this.alert.show = true;
							this.loadingPostRequest = false;
						}
					})
					.catch(() => {
						//general request failure
						this.alert.message = "Something went wrong.";
						this.alert.variant = "danger";
						this.alert.show = true;
					})
					.finally(() => {
						this.loadingPostRequest = false;
					});
			}
		},
	},
};
</script>