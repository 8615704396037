<style scoped>
.modal-header .close {
	display: none;
}
.signing-area {
	border: 1px solid black;
}
</style>
<template>
	<div>
		<div class="invoice" :style="usingAccessCode ? 'margin: 24px;' : ''">
			<b-card v-if="loadingGetRequest">
				<b-skeleton animation="throb" width="85%"></b-skeleton>
				<b-skeleton animation="throb" width="55%"></b-skeleton>
				<b-skeleton animation="throb" width="70%"></b-skeleton>
				<b-skeleton animation="throb" width="85%"></b-skeleton>
				<b-skeleton animation="throb" width="55%"></b-skeleton>
				<b-skeleton animation="throb" width="70%"></b-skeleton>
				<b-skeleton animation="throb" width="85%"></b-skeleton>
				<b-skeleton animation="throb" width="55%"></b-skeleton>
				<b-skeleton animation="throb" width="70%"></b-skeleton>
				<b-skeleton animation="throb" width="85%"></b-skeleton>
				<b-skeleton animation="throb" width="55%"></b-skeleton>
				<b-skeleton animation="throb" width="70%"></b-skeleton>
				<b-skeleton animation="throb" width="85%"></b-skeleton>
				<b-skeleton animation="throb" width="55%"></b-skeleton>
				<b-skeleton animation="throb" width="70%"></b-skeleton>
				<b-skeleton animation="throb" width="85%"></b-skeleton>
				<b-skeleton animation="throb" width="55%"></b-skeleton>
				<b-skeleton animation="throb" width="70%"></b-skeleton>
				<b-skeleton animation="throb" width="85%"></b-skeleton>
				<b-skeleton animation="throb" width="55%"></b-skeleton>
				<b-skeleton animation="throb" width="70%"></b-skeleton>
				<b-skeleton animation="throb" width="85%"></b-skeleton>
				<b-skeleton animation="throb" width="55%"></b-skeleton>
				<b-skeleton animation="throb" width="70%"></b-skeleton>
				<b-skeleton animation="throb" width="85%"></b-skeleton>
				<b-skeleton animation="throb" width="55%"></b-skeleton>
				<b-skeleton animation="throb" width="70%"></b-skeleton>
				<b-skeleton animation="throb" width="85%"></b-skeleton>
				<b-skeleton animation="throb" width="55%"></b-skeleton>
				<b-skeleton animation="throb" width="70%"></b-skeleton>
			</b-card>
			<div v-else>
				<!-- BEGIN invoice-company -->
				<div class="invoice-company">
					<span class="float-end hidden-print">
						<a href="javascript:;" class="btn btn-sm btn-white mb-10px me-2"><i class="fa fa-file-pdf t-plus-1 text-danger fa-fw fa-lg"></i> Export as PDF</a>
						<a href="javascript:;" onclick="window.print()" class="btn btn-sm btn-white mb-10px"><i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Print</a>
					</span>
					QUOTE
				</div>
				<!-- END invoice-company -->
				<!-- BEGIN invoice-header -->
				<div class="invoice-header">
					<div class="invoice-from">
						<small>from</small>
						<address class="mt-5px mb-5px">
							<strong class="text-dark">LI Technology Solutions Inc.</strong><br />
							62 Knollwood Avenue<br />
							Huntington, New York, 11743<br />
						</address>
					</div>
					<div class="invoice-to">
						<small>to</small>
						<address class="mt-5px mb-5px">
							<strong class="text-dark">{{ quote.contact.name }}</strong><br />
							{{ quote.contact.emailAddress }} <br />
							{{ quote.contact.addresses[0].addressLine1 }} <br />
							{{ quote.contact.addresses[0].city }}, {{ quote.contact.addresses[0].region }}, {{ quote.contact.addresses[0].postalCode }}<br />
						</address>
					</div>
					<div class="invoice-date">
						<small>Invoice</small>
						<div class="date text-dark mt-5px">{{ format(addHours(parseJSON(quote.date), 5), "LLL d, yyyy") }}</div>
						<div class="invoice-detail">
							#{{ quote.quoteNumber }}
						</div>
					</div>
				</div>
				<!-- END invoice-header -->
				<!-- BEGIN invoice-content -->
				<div class="invoice-content">
					<!-- BEGIN table-responsive -->
					<div class="table-responsive">
						<table class="table table-invoice">
							<thead>
								<tr>
									<th>DESCRIPTION</th>
									<th class="text-center" width="10%">Quantity</th>
									<th class="text-center" width="10%">Unit Price</th>
									<th class="text-end" width="10%">Tax</th>
									<th class="text-end" width="10%">Amount</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="lineItem in quote.lineItems" :key="lineItem._id">
									<td>
										<b>{{ lineItem.description }}</b>
									</td>
									<td class="text-center">{{ lineItem.quantity }}</td>
									<td class="text-center">{{ lineItem.unitAmount }} </td>
									<td class="text-end">{{ taxType(lineItem.taxType) }}</td>
									<td class="text-end">{{ Intl.NumberFormat('en-Us', { style: 'currency', currency: 'USD' }).format(lineItem.lineAmount) }}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<!-- END table-responsive -->
					<!-- BEGIN invoice-price -->
					<div class="invoice-price">
						<div class="invoice-price-left">
							<div class="invoice-price-row">
								<div class="sub-price">
									<small>SUBTOTAL</small>
									<span class="text-dark">{{ Intl.NumberFormat('en-Us', { style: 'currency', currency: 'USD' }).format(quote.subTotal) }}</span>
								</div>
								<div class="sub-price">
									<i class="fa fa-plus text-muted"></i>
								</div>
								<div class="sub-price">
									<small>Tax</small>
									<span class="text-dark">{{ Intl.NumberFormat('en-Us', { style: 'currency', currency: 'USD' }).format(quote.totalTax) }}</span>
								</div>
							</div>
						</div>
						<div class="invoice-price-right">
							<small>TOTAL</small> <span class="fw-bold">{{ Intl.NumberFormat('en-Us', { style: 'currency', currency: 'USD' }).format(quote.total) }}</span>
						</div>
					</div>
					<!-- END invoice-price -->
					<div style="text-align: end; margin-top:36px;" v-if="!quote.accepted">
						<b-button @click="showSignatureModal = true" variant="success" size="lg" pill>Sign and Accept</b-button>
					</div>
				</div>

				<b-modal v-model="showSignatureModal" centered>
					<template #modal-header="">
						<h5>Please Sign Below</h5>
					</template>
					<template #modal-footer="{ cancel }">
						<b-button size="sm" variant="danger" @click="undoLast">
							Undo
						</b-button>
						<b-button size="sm" variant="outline-secondary" @click="cancel()">
							Cancel
						</b-button>
						<b-button size="sm" variant="success" @click="signAndAccept">
							Sign and Accept
						</b-button>
					</template>
					<div class="signing-area">
						<VueSignaturePad id="signature" width="100%" height="200px" ref="signaturePad" :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()}}" />
					</div>
					<b-alert class="mt-4" v-if="pleaseSign" show variant="danger">You must sign above to accept</b-alert>
				</b-modal>

				<b-modal static v-model="signedWithSuccess" centered @hide="dontHide">
					<template #modal-header="">
						<h5>Thank You</h5>
					</template>
					<div style="font-size:14px;">We will be in contact with you soon.</div>
					<template #modal-footer="">
						<b-button size="sm" variant="outline-primary" @click="reloadPage">
							Okay
						</b-button>
					</template>
				</b-modal>
			</div>
		</div>
	</div>
</template>

<script>
import { keyBy } from "lodash";
import { format, parseJSON, addHours } from "date-fns";

import AppOptions from "../../config/AppOptions.vue";

export default {
	name: "QuotesDetailView",
	props: {
		id: {
			type: String,
			default: null,
		},
		accessCode: {
			type: String,
			default: null,
		},
		usingAccessCode: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			taxTypes: {},
			quote: {},
			getRequestHelperCount: 0,
			showSignatureModal: false,
			pleaseSign: false,
			signedWithSuccess: false,
		};
	},
	computed: {
		loadingGetRequest() {
			return this.getRequestHelperCount !== 2; //add more get requests, add more to this number!!
		},
	},
	methods: {
		format,
		parseJSON,
		addHours,
		dontHide(event) {
			event.preventDefault();
		},
		openSignatureModal() {
			this.$refs["signature-modal"].show();
		},
		undoLast() {
			this.$refs.signaturePad.undoSignature();
		},
		signAndAccept() {
			this.pleaseSign = false;

			const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

			if (isEmpty) {
				this.pleaseSign = true;
				return;
			}
			const postData = {
				signatureBase64: data,
			};

			console.log(postData);

			if (this.usingAccessCode) {
				// /client/v1/quote/byAccessCode/accept/697a6cf9-da94-46c6-ae82-502a73ec6274
				this.$http
					.post(
						`/client/v1/quote/byAccessCode/accept/${this.accessCode}`,
						postData
					)
					.then((response) => {
						console.log(response);
						this.showSignatureModal = false;
						this.signedWithSuccess = true;
					});
			} else {
				this.$http
					.post(`/client/v1/quote/accept/${this.id}`, postData)
					.then((response) => {
						console.log(response);
						this.showSignatureModal = false;
						this.signedWithSuccess = true;
					});
			}
		},
		reloadPage() {
			if (this.usingAccessCode) {
				location.reload();
				// Forcing user to reload here
				// We can have some sort of UI if the quote is Accepted, that doesn't allow them to resign but allows them to view the quote they signed
				// As well as display their signature?
				// Can also allow them to resign if you need to edit the quote, but I assume you would generate a new one in this case and not approve this quote
			} else {
				// for non access code users (signed in users) who have access to multiple companies
				// when you reload it defaults to the 0th index of the array
				// which breaks if the quote isn't for that company
				// so this is much much safer
				this.$router.push("/billing/quotes");
			}
		},
		taxType: function (type) {
			if (this.taxTypes[type]) {
				return this.taxTypes[type].name;
			} else if (type == undefined) {
				return "Tax Exempt";
			} else {
				return this.taxTypes[type.split("/")[1]].name;
			}
		},
		doAccessCodeFlow() {
			AppOptions.appEmpty = true;
			this.$http
				.get(`/client/v1/quote/byAccessCode/${this.accessCode}`)
				.then((response) => {
					this.quote = response.data.data.quote;
				})
				.finally(() => {
					this.getRequestHelperCount++;
				});
		},
		doIdFlow() {
			this.$http
				.get(`/client/v1/quote/${this.id}`)
				.then((response) => {
					this.quote = response.data.data.quote;
				})
				.finally(() => {
					this.getRequestHelperCount++;
				});
		},
		promptSignatureModal() {
			this.signatureModal = true;
		},
	},
	created() {
		this.$http
			.get("/v1/pending/charges/taxtypes")
			.then((response) => {
				this.taxTypes = keyBy(response.data.taxTypes, "taxType");
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				this.getRequestHelperCount++;
			});

		if (this.usingAccessCode) {
			// /billing/quotes/access/697a6cf9-da94-46c6-ae82-502a73ec6274
			this.doAccessCodeFlow();
		} else {
			this.doIdFlow();
		}
	},
};
</script>