<script>
const sidebarMenu = [
  {
    path: "/",
    icon: "fa fa-sitemap",
    title: "Home",
  },
  {
    path: "/assets",
    icon: "fa fa-microchip",
    title: "Assets",
    requiredPermission: ["Client_Owner", "Client_Manager"],
    children: [
      {
        path: "/assets/list",
        title: "Computer Assets",
        requiredPermission: ["Client_Owner", "Client_Manager"],
      },
    ],
  },
  {
    path: "/billing",
    icon: "fa fa-file-invoice",
    title: "Billing",
    requiredPermission: ["Client_Owner"],
    children: [
      {
        path: "/billing/listInvoices",
        title: "Invoice List",
        requiredPermission: ["Client_Owner"],
      },
      {
        path: "/billing/quotes",
        title: "Quotes",
        requiredPermission: ["Client_Owner"],
      },
    ],
  },
  {
    icon: "fa fa-pied-piper-pp",
    title: "Support",
    path: "/support",
    requiredPermission: ["Client_Owner", "Client_Manager"],
    children: [
      {
        path: "/support/listtickets",
        title: "Ticket List",
        requiredPermission: ["Client_Owner", "Client_Manager"],
      },
      {
        title: "New Support Request",
        runFunction: "openTypeFormModal",
        requiredPermission: ["Client_Owner", "Client_Manager"],
      },
    ],
  },
  {
    icon: "fa fa-users",
    title: "Microsoft",
    path: "/microsoft",
    requiredPermission: ["Client_Owner", "Client_Manager"],
    children: [
      {
        path: "/microsoft/users/list",
        title: "List Users",
        requiredPermission: ["Client_Owner", "Client_Manager"],
      },
    ],
  },
  {
    icon: "fa fa-users",
    title: "Sub Users",
    path: "/user",
    requiredPermission: ["Client_Owner"],
    children: [
      {
        path: "/user/list",
        title: "List",
        requiredPermission: ["Client_Owner"],
      },
      {
        path: "/user/add",
        title: "Add",
        requiredPermission: ["Client_Owner"],
      },
    ],
  },
];

export default sidebarMenu;
</script>
