<style scoped>
* {
  font-size: 14px !important;
}
.panel /deep/ .panel-heading .panel-title {
  font-size: 14px !important;
}
</style>
<template>
  <div>
    <div class="row">
      <!-- begin col-6 -->
      <div class="col-xl-12">
        <!-- begin panel -->
        <panel title="Asset List">
          <div class="panel-body">
            <list-skeleton v-if="loadingGetRequest" />
            <vue-good-table
              v-else
              :columns="columns"
              :rows="rows"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: numberOfRowsDisplayed,
              }"
            >
              <template slot="pagination-bottom" slot-scope="props">
                <custom-pagination
                  :total="props.total"
                  :pageChanged="props.pageChanged"
                  :perPageChanged="props.perPageChanged"
                >
                </custom-pagination>
              </template>
            </vue-good-table>
          </div>
        </panel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { format } from "date-fns";
import CustomPagination from "./../../components/CustomPagination.vue";
import ListSkeleton from "../../components/ListSkeleton.vue";

export default {
  name: "AssetList",
  components: {
    CustomPagination,
    ListSkeleton,
  },
  data() {
    return {
      columns: [
        {
          label: "Name",
          field: "ComputerName",
        },
        {
          label: "Online",
          field: "OnlineBoolean",
        },
        {
          label: "Group",
          field: "ShortMachineGroup",
        },
        {
          label: "Operating System",
          field: "ShortOS",
        },
        {
          label: "Asset Type",
          field: "AssetType",
        },
        {
          label: "Last Logged In User",
          field: "LastLoggedInUser",
        },
        {
          label: "Last Online Time",
          field: "LastOnline",
        },
        {
          label: "Asset Type",
          field: "audit.Chassis.ChassisType",
        },
        {
          label: "Manufacturer",
          field: "audit.SystemInfo.Manufacturer",
        },
        {
          label: "Model",
          field: "audit.SystemInfo.ProductName",
        },
      ],
      WorkStationOS: ["7", "8", "8.1", "10", "11", "Mac OS X"],
      rows: [],
      loadingGetRequest: true,
    };
  },
  computed: {
    ...mapGetters(["numberOfRowsDisplayed"]),
    chosenClient: function () {
      return this.$store.state.auth.chosenClientPermission;
    },
  },
  watch: {
    chosenClient() {
      this.getData();
    },
  },
  methods: {
    viewInvoice(id) {
      this.$router.push(`/billing/invoice/view/${id}`);
    },
    getData: function () {
      this.loadingGetRequest = true;
      this.$http
        .get(`/client/v1/assets/`)
        .then((response) => {
          this.rows = response.data.data.assets.map((x) => {
            x.isWorkstation = this.WorkStationOS.includes(x.isWorkstation);
            x.AssetType = this.WorkStationOS.includes(x.OSType)
              ? "Physical"
              : "Cloud";
            x.OnlineBoolean = x.Online == 1 ? "Yes" : "No";
            x.ShortMachineGroup = x.MachineGroup.split(".")[0].toUpperCase();
            x.ShortOS =
              x.OSType.indexOf("Max OS X") > -1
                ? x.OSType
                : `Windows ${x.OSType}`;
            x.LastOnline = format(new Date(x.LastCheckInTime), "MM/dd/yyyy");
            return x;
          });
        })
        .finally(() => {
          this.loadingGetRequest = false;
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>
