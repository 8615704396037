<style scoped>
.custom-container {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
}
.custom-btn {
  width: 90px;
  height: 40px;
  margin: 0px 4px 0px 4px;
}
.custom-btn:focus {
  outline: none;
  box-shadow: none;
}
.btn-container {
  padding-left: 16px;
}
.custom-txt {
  font-weight: 600;
  font-size: 12px;
  color: gray;
}
.custom-dropdown {
  margin-left: 12px;
  height: 36px;
  width: 56px;
}
.left-side-container {
  padding-left: 8px;
}
.right-side-container {
  padding-right: 8px;
  display: flex;
  align-items: center;
}
</style>
<template>
  <div class="custom-container">
    <div class="left-side-container">
      <span class="custom-txt">Rows per page: </span>
      <b-form-select
        class="custom-dropdown"
        v-model="selected"
        :options="options"
        @change="onChangeSelected"
      />
    </div>
    <div class="right-side-container">
      <div class="custom-txt limit-txt">
        {{ lowerLimit }} - {{ upperLimit }} of {{ total }}
      </div>
      <div class="btn-container">
        <b-button
          @click="onClickPrevious"
          class="custom-btn"
          :disabled="lowerLimit <= 1"
          variant="outline-secondary"
          ><i class="fa fa-chevron-left" /> Previous</b-button
        >
        <b-button
          @click="onClickNext"
          class="custom-btn"
          variant="outline-secondary"
          :disabled="upperLimit >= total"
          >Next <i class="fa fa-chevron-right" />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "custom-pagination",
  props: {
    total: {
      type: Number,
      required: true,
    },
    pageChanged: {
      type: Function,
    },
    perPageChanged: {
      type: Function,
    },
  },
  data() {
    return {
      selected: "",
      options: [10, 20, 30, 40, 50],
      lowerLimit: undefined,
      upperLimit: undefined,
      currentPage: 1,
    };
  },
  computed: { ...mapGetters(["numberOfRowsDisplayed"]) },
  methods: {
    ...mapActions(["changeNumberOfRows"]),
    onClickNext() {
      const uL = (this.upperLimit += this.selected);
      this.customPageChange(++this.currentPage);
      this.lowerLimit += this.selected;

      if (uL > this.total) {
        this.upperLimit = this.total;
      } else {
        this.upperLimit = uL;
      }
    },
    onClickPrevious() {
      const diff = this.upperLimit - this.lowerLimit;
      if (diff < this.total - 1) {
        this.upperLimit = this.upperLimit - (diff + 1);
      } else {
        this.upperLimit -= this.selected;
      }
      const lL = (this.lowerLimit -= this.selected);
      this.customPageChange(--this.currentPage);

      if (lL < 1) {
        this.lowerLimit = 1;
      } else {
        this.lowerLimit = lL;
      }
    },
    onChangeSelected() {
      localStorage.setItem("nRows", this.selected);
      this.changeNumberOfRows(this.selected);
      this.lowerLimit = this.total === 0 ? 0 : 1;
      if (this.selected > this.total) {
        this.upperLimit = this.total;
      } else {
        this.upperLimit = this.selected;
      }
      this.customPerPageChange(this.selected);
      this.customPageChange(1);
      this.currentPage = 1;
    },
    customPageChange(customCurrentPage) {
      this.pageChanged({ currentPage: customCurrentPage });
    },
    customPerPageChange(customPerPage) {
      this.perPageChanged({ currentPerPage: customPerPage });
    },
  },
  async created() {
    const nRows = parseInt(localStorage.getItem("nRows"));

    if (!isNaN(nRows)) {
      if (nRows !== this.numberOfRowsDisplayed) {
        this.changeNumberOfRows(nRows);

        setTimeout(() => {
          this.customPerPageChange(nRows);
          this.customPageChange(1);
        }, 0);
      }
    }
    this.lowerLimit = this.total === 0 ? 0 : 1;
    this.selected = this.numberOfRowsDisplayed;
    if (this.selected > this.total) {
      this.upperLimit = this.total;
    } else {
      this.upperLimit = this.selected;
    }
  },
};
</script>
