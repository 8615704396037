<style scoped>
* {
  font-size: 14px !important;
}
.panel /deep/ .panel-heading .panel-title {
  font-size: 14px !important;
}
</style>
<template>
  <div>
    <div class="row">
      <!-- begin col-6 -->
      <div class="col-xl-12">
        <!-- begin panel -->
        <panel title="Invoice List">
          <div class="panel-body">
            <list-skeleton v-if="loadingGetRequest" />
            <!-- <vue-good-table v-else :columns="columns" :rows="rows" :pagination-options="{
                                enabled: true,
                                mode: 'records',
                                perPage: 10,
                                position: 'top'
                            }" :search-options="{
                                enabled: true 
                            }">
							<template slot="table-row" slot-scope="props">
								<span v-if="props.column.field == 'action'">
									<a class="btn btn-primary btn-sm" v-on:click="viewInvoice(props.row._id)">View</a>
								</span>
								<span v-else>
									{{ props.formattedRow[props.column.field] }}
								</span>
							</template>
						</vue-good-table> -->
            <vue-good-table
              v-else
              :columns="columns"
              :rows="rows"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: numberOfRowsDisplayed,
              }"
            >
              <template slot="pagination-bottom" slot-scope="props">
                <custom-pagination
                  :total="props.total"
                  :pageChanged="props.pageChanged"
                  :perPageChanged="props.perPageChanged"
                >
                </custom-pagination>
              </template>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                  <a
                    class="btn btn-primary btn-sm"
                    v-on:click="viewInvoice(props.row._id)"
                    >View</a
                  >
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </panel>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import { mapGetters } from "vuex";
import CustomPagination from "./../../components/CustomPagination.vue";
import ListSkeleton from "../../components/ListSkeleton.vue";

export default {
  name: "InvoiceList",
  components: {
    CustomPagination,
    ListSkeleton,
  },
  data() {
    return {
      columns: [
        {
          label: "Invoice Number",
          field: "invoiceNumber",
        },
        {
          label: "Amount Paid",
          field: "amountPaid",
        },
        {
          label: "Amount Due",
          field: "amountDue",
        },
        {
          label: "Amount Credited",
          field: "amountCredited",
        },
        {
          label: "Date",
          field: "date",
        },
        {
          label: "Total",
          field: "total",
        },
        {
          label: "Due Date",
          field: "dueDate",
        },
        {
          label: "Paid",
          field: "fullyPaidOnDate",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "",
          field: "action",
        },
      ],
      rows: [],
      loadingGetRequest: true,
    };
  },
  computed: {
    ...mapGetters(["numberOfRowsDisplayed"]),
    chosenClient: function () {
      return this.$store.state.auth.chosenClientPermission;
    },
  },
  watch: {
    chosenClient() {
      this.getData();
    },
  },
  methods: {
    viewInvoice(id) {
      this.$router.push(`/billing/invoice/view/${id}`);
    },
    getData: function () {
      this.loadingGetRequest = true;
      this.$http
        .get(`/client/v1/invoice/`)
        .then((response) => {
          this.rows = response.data.data.invoices.map((x) => {
            x.dueDate = format(new Date(x.dueDate), "MM/dd/yyyy");
            x.fullyPaidOnDate = format(
              new Date(x.fullyPaidOnDate),
              "MM/dd/yyyy"
            );
            return x;
          });
        })
        .finally(() => {
          this.loadingGetRequest = false;
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>
