import Vue from "vue";
import VueX from "vuex";
import store from "./store";
import routes from "./config/AppRoutes";

// plugins
import VueRouter from "vue-router";
import VueBootstrap from "bootstrap-vue";
import VueNVD3 from "vue-nvd3";
import VueInsProgressBar from "vue-ins-progress-bar";
import VueEventCalendar from "vue-event-calendar";
import VueSparkline from "vue-sparklines";
import * as VueGoogleMaps from "vue2-google-maps";
import Vueditor from "@agametov/vueditor";
import VueHljs from "vue-hljs";
import hljs from "highlight.js";
import VueSweetalert2 from "vue-sweetalert2";
import VueNotification from "vue-notification";
import VuePanel from "./plugins/panel/";
import VueDateTimePicker from "vue-bootstrap-datetimepicker";
import VueSelect from "vue-select";
import VueDatepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import VueMaskedInput from "vue-maskedinput";
import VueInputTag from "vue-input-tag";
import VueSlider from "vue-slider-component";
import VueGoodTable from "vue-good-table";
import VueCountdown from "@chenfengyuan/vue-countdown";
import VueColorpicker from "vue-pop-colorpicker";
import VueCustomScrollbar from "vue-custom-scrollbar";
import VueApexCharts from "vue-apexcharts";
import DateRangePicker from "vue2-daterange-picker";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";

import axios from "./plugins/axios";

// plugins css
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "nvd3/build/nv.d3.min.css";
import "vue-event-calendar/dist/style.css";
import "vue-hljs/dist/style.css";
import "@agametov/vueditor/dist/style/vueditor.min.css";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import "simple-line-icons/css/simple-line-icons.css";
import "flag-icon-css/css/flag-icons.min.css";
import "ionicons/dist/css/ionicons.min.css";
import "vue-good-table/dist/vue-good-table.css";
import "vue-select/dist/vue-select.css";
import "vue-slider-component/theme/antd.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import VueSignaturePad from "vue-signature-pad";

// color admin css
import "./scss/vue.scss";
import "bootstrap-social/bootstrap-social.css";

import VueCompositionAPI from "@vue/composition-api";

import App from "./App.vue";

Vue.prototype.$http = axios;

Vue.config.productionTip = false;

Vue.use(VueX);
Vue.use(VueRouter);
Vue.use(VueBootstrap);
Vue.use(VueNVD3);
Vue.use(VueEventCalendar, { locale: "en" });
Vue.use(VueSparkline);
Vue.use(Vueditor);
Vue.use(VueHljs, { hljs });
Vue.use(VueSweetalert2);
Vue.use(VueNotification);
Vue.use(VuePanel);
Vue.use(VueDateTimePicker);
Vue.use(VueGoodTable);
Vue.use(VueColorpicker);
Vue.use(VueSignaturePad);

Vue.use(VueCompositionAPI);

Vue.use(VueGoogleMaps, {
	load: {
		key: "",
		libraries: "places",
	},
});
Vue.use(VueInsProgressBar, {
	position: "fixed",
	show: true,
	height: "3px",
});
Vue.component("v-select", VueSelect);
Vue.component("datepicker", VueDatepicker);
Vue.component("masked-input", VueMaskedInput);
Vue.component("input-tag", VueInputTag);
Vue.component("vue-slider", VueSlider);
Vue.component("vue-custom-scrollbar", VueCustomScrollbar);
Vue.component("apexchart", VueApexCharts);
Vue.component("date-range-picker", DateRangePicker);
Vue.component(VueCountdown.name, VueCountdown);

Vue.prototype.$http = axios;

Vue.prototype.$fingerprint = FingerprintJS.load({
	token: "4Auw6rHsBW4dZMR6UITz",
	endpoint: "https://metrics.litech123.com",
});

const router = new VueRouter({
	routes,
	mode: "history",
});

const user = localStorage.getItem("currentUser");

if (user) {
	let u = JSON.parse(user);
	Vue.prototype.$http.defaults.headers.common["Authorization"] = u.token;
	store.commit("auth/changeClient", u.clientPermission[0]);
	store.commit("auth/initialSetState", u);
	// also launch the check for valid token at some point...
}

Vue.prototype.$http.interceptors.response.use("ERROR", function (err) {
	if (err.response.status === 401) {
		store.dispatch("auth/logout");
		router.push("/login");
	}
});

if (process.env.NODE_ENV !== "development") {
	/*Vue.prototype.$rollbar = new Rollbar({
        accessToken: "15e5cd86e2f44ee18f5a6dc4d53381af",
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
            client: {
                javascript: {
                    source_map_enabled: true,
                    guess_uncaught_frames: true,
                }
            }
        }
    });

    Vue.config.errorHandler = (err, vm) => {
        vm.$rollbar.error(err);
        throw err; // rethrow
    }; */
}

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.loginRequired)) {
		/* eslint-disable no-extra-boolean-cast */
		if (!!store.state.auth.token) {
			return next();
		} else {
			return next("/login");
		}
	}
	return next();
});

new Vue({
	render: (h) => h(App),
	router,
	store,
}).$mount("#app");
