<template>
  <div>
    <!-- BEGIN login -->
    <div class="login login-v2 fw-bold">
      <!-- BEGIN login-cover -->
      <div class="login-cover">
        <div
          class="login-cover-img"
          v-bind:style="{ backgroundImage: 'url(' + bg.activeImg + ')' }"
        ></div>
        <div class="login-cover-bg"></div>
      </div>
      <!-- END login-cover -->

      <!-- BEGIN login-container -->
      <div class="login-container">
        <!-- BEGIN login-header -->
        <div class="login-header">
          <div class="brand">
            <div class="d-flex align-items-center">
              <span class="logo"></span> <b>LITech</b> Portal
            </div>
            <small>Signing in, one moment...</small>
          </div>
          <div class="icon">
            <i class="fa fa-lock"></i>
          </div>
        </div>
        <!-- END login-header -->

        <!-- BEGIN login-content -->
        <!-- END login-content -->
      </div>
      <!-- END login-container -->
    </div>
    <!-- END login -->
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";

import AppOptions from "../../config/AppOptions.vue";

const includesOneLowercase = (value) => {
  return value.toUpperCase() != value;
};

const includesOneUppercase = (value) => {
  return value.toLowerCase() != value;
};

const includesOneDigit = (value) => {
  const isValid = /\d/.test(value);
  return isValid;
};

const includesOneSymbol = (value) => {
  const isValid = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value); //  /^[@$!%*?&]+/g.test(value);
  return isValid;
};

export default {
  name: "PasswordReset",
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    id: String,
  },
  created() {
    AppOptions.appEmpty = true;
    this.$fingerprint
      .then((fp) => fp.get())
      .then((result) => {
        this.form.visitorID = result.visitorId;
      });
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appEmpty = false;
    next();
  },
  beforeCreate: function () {
    this.$fingerprint
      .then((fp) => fp.get())
      .then((result) => {
        this.form.visitorID = result.visitorId;
      });
  },
  data() {
    return {
      bg: {
        activeImg: "/assets/img/login-bg/login-bg-17.jpg",
      },
      iconClasses: {
        pass: "fas fa-check-circle text-success mx-2",
        fail: "fas fa-times-circle text-danger mx-2",
      },
      form: {
        password: "",
        confirm_password: "",
        visitorID: "",
      },
      displayPasswordRules: false,
      alert: {
        show: false,
        message: "",
        variant: "danger",
      },
      loadingPostRequest: false,
    };
  },
  validations() {
    return {
      form: {
        password: {
          required,
          minLength: minLength(12),
          includesOneLowercase,
          includesOneUppercase,
          includesOneDigit,
          includesOneSymbol,
        },
        confirm_password: {
          required,
          sameAsPassword: sameAs(this.form.password),
        },
      },
    };
  },
  mounted: function () {
    this.$fingerprint.then((fp) => {
      // todo fucking fix, why would this not work?
      fp.get().then((result) => {
        this.$http
          .post("/client/v2/user/activate", {
            id: this.id,
            rememberMe: true,
            visitorID: result.visitorId,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.$store.commit("auth/auth_success", response.data.data);
              this.$router.push("/home");
            } else {
              this.alert.message = response.data.message.content;
              this.alert.variant = "danger";
              this.alert.show = true;
              this.loadingPostRequest = false;
            }
          });
      });
    });
  },
};
</script>
