<template>
	<div>
	</div>
</template>

<script>
export default {
	created() {
		this.$store.commit("auth/user_logout");
		this.$router.push("/login");
	},
};
</script>