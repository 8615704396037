<script>
import Home from "../pages/Home.vue";
import NotFound from "../pages/NotFound.vue";
import Login from "../pages/Login/Login.vue";
import Logout from "../pages/Logout/Logout.vue";
import Impersonate from "../pages/Login/Impersonate.vue";
import PasswordReset from "../pages/Login/PasswordReset.vue";
import ChangePassword from "../pages/Account/ChangePassword.vue";
import OnboardingInstructions from "../pages/Onboarding/OnboardingInstructions.vue";
import BillingListInvoice from "../pages/Billing/list_invoices.vue";
import BillingViewInvoice from "../pages/Billing/view_invoice.vue";
import QuotesInventoryView from "../pages/Billing/quotes_inventory.vue";
import QuotesDetailView from "../pages/Billing/quotes_detail.vue";
import TicketsList from "../pages/Tickets/list_tickets.vue";
import MicrosoftUserList from "../pages/Microsoft/list_users.vue";
import AssetList from "../pages/Assets/list.vue";
import ListUsers from "../pages/user/list.vue";
import AddUser from "../pages/user/add.vue";
import WelcomeActivate from "../pages/Login/WelcomeActivate.vue";

const routes = [
	{
		path: "*",
		redirect: "/home",
	},
	{
		path: "/home",
		component: Home,
		meta: { loginRequired: true },
	},
	{
		path: "/Login",
		component: Login,
	},
	{
		path: "/Logout",
		component: Logout,
	},
	{
		path: "/ChangePassword",
		component: ChangePassword,
		meta: { loginRequired: true },
	},
	{
		path: "/impersonate/:token",
		component: Impersonate,
		props: true,
	},
	{
		path: "/Login/:token",
		component: Login,
		props: true,
	},
	{
		path: "/PasswordReset/:id",
		component: PasswordReset,
		props: true,
	},
	{
		path: "/welcome/activate/:id",
		component: WelcomeActivate,
		props: true,
	},
	{
		path: "/billing/listInvoices",
		component: BillingListInvoice,
		meta: { loginRequired: true },
	},
	{
		path: "/billing/invoice/view/:id",
		component: BillingViewInvoice,
		meta: { loginRequired: true },
		props: true,
	},
	{
		path: "/billing/quotes",
		component: QuotesInventoryView,
		meta: { loginRequired: true },
	},
	{
		path: "/billing/quotes/:id",
		component: QuotesDetailView,
		meta: { loginRequired: true },
		props: true,
	},
	{
		path: "/billing/quotes/access/:accessCode",
		component: QuotesDetailView,
		props: (route) => ({
			usingAccessCode: true,
			accessCode: route.params.accessCode,
		}),
	},
	{
		path: "/microsoft/users/list",
		component: MicrosoftUserList,
		meta: { loginRequired: true },
	},
	{
		path: "/support/listtickets",
		component: TicketsList,
		meta: { loginRequired: true },
	},
	{
		path: "/assets/list",
		component: AssetList,
		meta: { loginRequired: true },
	},
	{
		path: "/user/list",
		component: ListUsers,
		meta: { loginRequired: true },
	},
	{
		path: "/user/add",
		component: AddUser,
		meta: { loginRequired: true },
	},
	{ path: "/NotFound", component: NotFound },
	{
		path: "/user/onboard/accesscode/:token",
		component: OnboardingInstructions,
		props: true,
	},
];

export default routes;
</script>
