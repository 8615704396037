<style scoped>
* {
  font-size: 14px !important;
}
.panel /deep/ .panel-heading .panel-title {
  font-size: 14px !important;
}
.panel
  .panel-body
  .panel-body
  .vgt-wrap
  /deep/
  .vgt-inner-wrap
  .vgt-responsive
  #vgt-table {
  font-size: 14px;
}
</style>
<template>
  <div>
    <div class="row">
      <!-- begin col-6 -->
      <div class="col-xl-12">
        <!-- begin panel -->
        <panel title="Ticket List">
          <div class="panel-body">
            <list-skeleton v-if="loadingGetRequest" />

            <vue-good-table
              v-else
              :columns="columns"
              :rows="rows"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: numberOfRowsDisplayed,
              }"
              :search-options="{
                enabled: true,
              }"
            >
              <template slot="pagination-bottom" slot-scope="props">
                <custom-pagination
                  :total="props.total"
                  :pageChanged="props.pageChanged"
                  :perPageChanged="props.perPageChanged"
                >
                </custom-pagination>
              </template>
            </vue-good-table>
          </div>
        </panel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { format, parseJSON } from "date-fns";
import CustomPagination from "./../../components/CustomPagination";
import ListSkeleton from "../../components/ListSkeleton.vue";

export default {
  name: "TicketList",
  components: {
    CustomPagination,
    ListSkeleton,
  },
  data() {
    return {
      columns: [
        {
          label: "Ticket ID",
          field: "id",
        },
        {
          label: "Type",
          field: "type",
        },
        {
          label: "Subject",
          field: "subject",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Priority",
          field: "priority",
        },
        {
          label: "Created",
          field: "created_at",
        },
      ],
      rows: [],
      maps: {
        status: {
          2: "Open",
          3: "Pending",
          4: "Resolved",
          5: "Closed",
        },
        priority: {
          1: "Low",
          2: "Medium",
          3: "High",
          4: "Urgent",
        },
        sourceType: {
          1: "Email",
          2: "Portal",
          3: "Phone",
          4: "Forum",
          5: "Twitter",
          6: "Facebook",
          7: "Chat",
        },
      },
      loadingGetRequest: true,
    };
  },
  methods: {
    getData: function () {
      this.loadingGetRequest = true;
      this.$http
        .get(`/client/v1/ticket/`)
        .then((response) => {
          this.rows = response.data.data.tickets.map((x) => {
            x.status = this.maps.status[x.status];
            x.priority = this.maps.priority[x.priority];
            x.created_at = format(parseJSON(x.created_at), "yyyy-MM-dd");
            return x;
          });
        })
        .finally(() => {
          this.loadingGetRequest = false;
        });
    },
  },
  computed: {
    ...mapGetters(["numberOfRowsDisplayed"]),
    chosenClient: function () {
      return this.$store.state.auth.chosenClientPermission;
    },
  },
  watch: {
    chosenClient() {
      this.getData();
    },
  },
  created() {
    this.getData();
  },
};
</script>
