<template>
	<div>
		<b-row class="mx-auto justify-content-center">
			<b-col class="mx-auto" lg="8" md="9" sm="10">
				<b-card v-if="loading" class="mb-2 mt-4">
					<b-row class="mt-2">
						<b-col>
							<h1>
								<b-skeleton animation="throb" width="55%"></b-skeleton>
							</h1>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col>
							<h4>
								<b-skeleton animation="throb" width="70%"></b-skeleton>
							</h4>
						</b-col>
						<b-col />
					</b-row>
					<b-row class="mt-4">
						<b-col>
							<b-skeleton animation="throb" width="85%"></b-skeleton>
							<b-skeleton animation="throb" width="55%"></b-skeleton>
							<b-skeleton animation="throb" width="70%"></b-skeleton>

							<b-skeleton animation="throb" width="85%"></b-skeleton>
							<b-skeleton animation="throb" width="55%"></b-skeleton>
							<b-skeleton animation="throb" width="70%"></b-skeleton>

							<b-skeleton animation="throb" width="85%"></b-skeleton>
							<b-skeleton animation="throb" width="55%"></b-skeleton>
							<b-skeleton animation="throb" width="70%"></b-skeleton>

							<b-skeleton animation="throb" width="85%"></b-skeleton>
							<b-skeleton animation="throb" width="55%"></b-skeleton>
							<b-skeleton animation="throb" width="70%"></b-skeleton>
						</b-col>
					</b-row>
				</b-card>
				<b-card v-else :title="templateName" class="mb-2 mt-4">
					<b-row class="mt-2">
						<b-col>
							<h1>{{businessName}}</h1>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col>
							<h4>Instructions for: {{fullname}}</h4>
						</b-col>
						<b-col />
					</b-row>
					<b-row class="mt-4">
						<b-col>
							<div v-html="htmlInstructions" />
						</b-col>
					</b-row>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import AppOptions from "../../config/AppOptions.vue";

export default {
	name: "OnboardingInstructions",
	created() {
		AppOptions.appEmpty = true;

		this.getInstructions();
	},
	beforeRouteLeave(to, from, next) {
		AppOptions.appEmpty = false;
		next();
	},
	props: {
		token: String,
	},
	data() {
		return {
			loading: true,
			fullname: "",
			businessName: "",
			templateName: "",
			htmlInstructions: "",
		};
	},
	methods: {
		getInstructions() {
			//"https://api.litech123.com/client/v1/onboard/pageinfo/f75de387-4405-47ed-bca0-8c0e9aa8cf2d
			this.loading = true;
			let url =
				"https://api.litech123.com/client/v1/onboard/pageinfo/" +
				this.token;

			this.$http
				.get(url)
				.then((response) => {
					let res = response.data.info;

					this.fullname = res.fullname;
					this.businessName = res.client.businessName;
					this.templateName = res.template.name;
					this.htmlInstructions = res.template.HTMLContent;

					this.loading = false;
				})
				.catch((error) => {
					console.log(error);
					//todo: handle failures
				});
		},
	},
};
</script>