<template>
	<div>
		<div class="invoice">
			<b-card v-if="loadingGetRequest">
				<b-skeleton animation="throb" width="85%"></b-skeleton>
				<b-skeleton animation="throb" width="55%"></b-skeleton>
				<b-skeleton animation="throb" width="70%"></b-skeleton>
				<b-skeleton animation="throb" width="85%"></b-skeleton>
				<b-skeleton animation="throb" width="55%"></b-skeleton>
				<b-skeleton animation="throb" width="70%"></b-skeleton>
				<b-skeleton animation="throb" width="85%"></b-skeleton>
				<b-skeleton animation="throb" width="55%"></b-skeleton>
				<b-skeleton animation="throb" width="70%"></b-skeleton>
				<b-skeleton animation="throb" width="85%"></b-skeleton>
				<b-skeleton animation="throb" width="55%"></b-skeleton>
				<b-skeleton animation="throb" width="70%"></b-skeleton>
				<b-skeleton animation="throb" width="85%"></b-skeleton>
				<b-skeleton animation="throb" width="55%"></b-skeleton>
				<b-skeleton animation="throb" width="70%"></b-skeleton>
				<b-skeleton animation="throb" width="85%"></b-skeleton>
				<b-skeleton animation="throb" width="55%"></b-skeleton>
				<b-skeleton animation="throb" width="70%"></b-skeleton>
				<b-skeleton animation="throb" width="85%"></b-skeleton>
				<b-skeleton animation="throb" width="55%"></b-skeleton>
				<b-skeleton animation="throb" width="70%"></b-skeleton>
				<b-skeleton animation="throb" width="85%"></b-skeleton>
				<b-skeleton animation="throb" width="55%"></b-skeleton>
				<b-skeleton animation="throb" width="70%"></b-skeleton>
				<b-skeleton animation="throb" width="85%"></b-skeleton>
				<b-skeleton animation="throb" width="55%"></b-skeleton>
				<b-skeleton animation="throb" width="70%"></b-skeleton>
				<b-skeleton animation="throb" width="85%"></b-skeleton>
				<b-skeleton animation="throb" width="55%"></b-skeleton>
				<b-skeleton animation="throb" width="70%"></b-skeleton>
			</b-card>
			<div v-else>
				<!-- BEGIN invoice-company -->
				<div class="invoice-company">
					<span class="float-end hidden-print">
						<a href="javascript:;" class="btn btn-sm btn-white mb-10px me-2"><i class="fa fa-file-pdf t-plus-1 text-danger fa-fw fa-lg"></i> Export as PDF</a>
						<a href="javascript:;" onclick="window.print()" class="btn btn-sm btn-white mb-10px"><i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Print</a>
					</span>
					INVOICE
				</div>
				<!-- END invoice-company -->
				<!-- BEGIN invoice-header -->
				<div class="invoice-header">
					<div class="invoice-from">
						<small>from</small>
						<address class="mt-5px mb-5px">
							<strong class="text-dark">LI Technology Solutions Inc.</strong><br />
							62 Knollwood Avenue<br />
							Huntington, New York, 11743<br />
						</address>
					</div>
					<div class="invoice-to">
						<small>to</small>
						<address class="mt-5px mb-5px">
							<strong class="text-dark">{{ invoice.contact.name }}</strong><br />
							{{ invoice.contact.emailAddress }} <br />
							{{ invoice.contact.addresses[0].addressLine1 }} <br />
							{{ invoice.contact.addresses[0].city }}, {{ invoice.contact.addresses[0].region }}, {{ invoice.contact.addresses[0].postalCode }}<br />
						</address>
					</div>
					<div class="invoice-date">
						<small>Invoice</small>
						<div class="date text-dark mt-5px">{{ format(addHours(parseJSON(invoice.date), 5), "LLL d, yyyy") }}</div>
						<div class="invoice-detail">
							#{{ invoice.invoiceNumber }}
						</div>
					</div>
				</div>
				<!-- END invoice-header -->
				<!-- BEGIN invoice-content -->
				<div class="invoice-content">
					<!-- BEGIN table-responsive -->
					<div class="table-responsive">
						<table class="table table-invoice">
							<thead>
								<tr>
									<th>DESCRIPTION</th>
									<th class="text-center" width="10%">Quantity</th>
									<th class="text-center" width="10%">Unit Price</th>
									<th class="text-end" width="10%">Tax</th>
									<th class="text-end" width="10%">Amount</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="lineItem in invoice.lineItems" :key="lineItem._id">
									<td>
										<b>{{ lineItem.description }}</b>
									</td>
									<td class="text-center">{{ lineItem.quantity }}</td>
									<td class="text-center">{{ lineItem.unitAmount }} </td>
									<td class="text-end">{{ taxType(lineItem.taxType) }}</td>
									<td class="text-end">{{ Intl.NumberFormat('en-Us', { style: 'currency', currency: 'USD' }).format(lineItem.lineAmount) }}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<!-- END table-responsive -->
					<!-- BEGIN invoice-price -->
					<div class="invoice-price">
						<div class="invoice-price-left">
							<div class="invoice-price-row">
								<div class="sub-price">
									<small>SUBTOTAL</small>
									<span class="text-dark">{{ Intl.NumberFormat('en-Us', { style: 'currency', currency: 'USD' }).format(invoice.subTotal) }}</span>
								</div>
								<div class="sub-price">
									<i class="fa fa-plus text-muted"></i>
								</div>
								<div class="sub-price">
									<small>Tax</small>
									<span class="text-dark">{{ Intl.NumberFormat('en-Us', { style: 'currency', currency: 'USD' }).format(invoice.totalTax) }}</span>
								</div>
							</div>
						</div>
						<div class="invoice-price-right">
							<small>TOTAL</small> <span class="fw-bold">{{ Intl.NumberFormat('en-Us', { style: 'currency', currency: 'USD' }).format(invoice.total) }}</span>
						</div>
					</div>
					<!-- END invoice-price -->
				</div>
				<!-- END invoice-content -->
			</div>
		</div>
	</div>
</template>

<script>
import { keyBy } from "lodash";
import { format, parseJSON, addHours } from "date-fns";

export default {
	name: "InvoiceList",
	props: {
		id: String,
	},
	data() {
		return {
			invoice: {},
			taxTypes: {},
			getRequestHelperCount: 0,
		};
	},
	methods: {
		format,
		parseJSON,
		addHours,
		taxType: function (type) {
			if (this.taxTypes[type]) {
				return this.taxTypes[type].name;
			} else if (type == undefined) {
				return "Tax Exempt";
			} else {
				return this.taxTypes[type.split("/")[1]].name;
			}
		},
	},
	computed: {
		loadingGetRequest() {
			return this.getRequestHelperCount !== 2; //add more get requests, add more to this number!!
		},
	},
	created() {
		this.$http
			.get("/v1/pending/charges/taxtypes")
			.then((response) => {
				this.taxTypes = keyBy(response.data.taxTypes, "taxType");
			})
			.finally(() => {
				this.getRequestHelperCount++;
			});
		this.$http
			.get(`/client/v1/invoice/${this.id}`)
			.then((response) => {
				this.invoice = response.data.data.invoice;
			})
			.finally(() => {
				this.getRequestHelperCount++;
			});
	},
};
</script>