import Vue from 'vue';


const state = {
    emailAddress: '',
    firstName: '',
    lastName: '',
    token: '',
    clientPermission: [],
    chosenClientPermission: '',
    accountLevel: ''
};

const getters = {
    currentUser: state => state,
    emailAddress: state => state.emailAddress,
    fullName: state => `${state.firstName} ${state.lastName}`,
    chosenClientPermission: state => state.chosenClientPermission,
    getAccountLevel: state => state.accountLevel
};

const actions = {
    logIn({ commit }, data) { // OLD
        return new Promise((resolve, reject) => {
            Vue.prototype.$http.post('v1/user/login', data).then((rawResponse) => {
                let response = rawResponse.data;
                if (response.status && response.status === 'success') {
                    localStorage.setItem('currentUser', JSON.stringify(response.data));
                    Vue.prototype.$http.defaults.headers.common["Authorization"] = response.data.token;
                    commit('auth_success', response.data);
                }
                resolve(rawResponse);
            }).catch(error => {
                reject(error);
            })
        });
    },
    async validateLogin() {
        // call the server, validate the login, do things correctly based on response... TODO

    },
    logout({ commit }) {
        commit('user_logout');
    }
};

const mutations = {
    auth_success: (state, data) => {
        Object.keys(data).forEach(function (key) {
            state[key] = data[key]
        });
        if(state.chosenClientPermission == "") {
            state.chosenClientPermission = state.clientPermission[0]; 
        }
        Vue.prototype.$http.defaults.headers.common["company_id"] = state.chosenClientPermission._id;
        Vue.prototype.$http.defaults.headers.common["Authorization"] = state.token;
        localStorage.setItem('currentUser', JSON.stringify(state));
    },
    user_logout: (state) => {
        localStorage.removeItem('currentUser');
        Vue.prototype.$http.defaults.headers.common["Authorization"] = '';
        Vue.prototype.$http.defaults.headers.common["company_id"] = '';
        state.emailAddress = '';
        state.firstName = '';
        state.lastName = '';
        state.token = '';
        state.clientPermission = '';
        state.chosenClientPermission = '';
        state.accountLevel = '';
    },
    initialSetState: (state, data) => {
        Object.keys(data).forEach(function (key) {
            state[key] = data[key]
        });
        // expected to have stuff set already as this is called when reading
    },
    changeClient: (state, client) => {
        state.chosenClientPermission = client;
        Vue.prototype.$http.defaults.headers.common["company_id"] = state.chosenClientPermission._id;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
