<template>
  <div>
    <div class="row">
      <!-- begin col-6 -->
      <div class="col-xl-12">
        <!-- begin panel -->
        <panel title="Microsoft User List">
          <div class="panel-body">
            <input type="checkbox" v-model="showDisabledUsers" /> Show Disabled
            Users?
            <input
              style="margin-left: 20px"
              type="checkbox"
              v-model="showExtUsers"
            />
            Show External Contacts?
            <!-- <vue-good-table
              :columns="columns"
              :rows="rows"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 50,
                position: 'top',
              }"
              :search-options="{
                enabled: true,
              }"
            >
            </vue-good-table> -->
            <list-skeleton v-if="loadingGetRequest" />
            <vue-good-table
              v-else
              :columns="columns"
              :rows="rows"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: numberOfRowsDisplayed,
              }"
              :search-options="{
                enabled: true,
              }"
            >
              <template slot="pagination-bottom" slot-scope="props">
                <custom-pagination
                  :total="props.total"
                  :pageChanged="props.pageChanged"
                  :perPageChanged="props.perPageChanged"
                >
                </custom-pagination>
              </template>
            </vue-good-table>
          </div>
        </panel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomPagination from "./../../components/CustomPagination";
import ListSkeleton from "../../components/ListSkeleton.vue";

export default {
  name: "UserList",
  components: {
    CustomPagination,
    ListSkeleton,
  },
  data() {
    return {
      columns: [
        {
          label: "User Principal name",
          field: "userPrincipalName",
          width: "20%",
        },
        {
          label: "Display Name",
          field: "displayName",
          width: "20%",
        },
        {
          label: "Licenses",
          field: "licenses",
          width: "20%",
        },
        {
          label: "Account Enabled",
          field: "accountEnabled",
          width: "20%",
        },
      ],
      filterOptions: {},
      showDisabledUsers: false,
      showExtUsers: false,
      rawRows: [],
      loadingGetRequest: true,
    };
  },
  methods: {
    getData() {
      this.loadingGetRequest = true;
      this.$http
        .get(`/client/v1/microsoft/users/`)
        .then((response) => {
          this.rawRows = response.data.data.users.map((x) => {
            x.licenses = x.licenseDetails
              .map((ld) => ld.skuPartNumber)
              .join(", ");
            return x;
          });
        })
        .finally(() => {
          this.loadingGetRequest = false;
        });
    },
    applyRowFilter(arg) {
      console.log(arg);
    },
  },
  computed: {
    ...mapGetters(["numberOfRowsDisplayed"]),
    chosenClient: function () {
      return this.$store.state.auth.chosenClientPermission;
    },
    rows() {
      let ret = this.rawRows;
      if (!this.showDisabledUsers) {
        ret = ret.filter((x) => x.accountEnabled);
      }
      if (!this.showExtUsers) {
        ret = ret.filter((x) => !x.userPrincipalName.includes("#EXT"));
      }

      return ret;
    },
  },
  watch: {
    chosenClient() {
      this.getData();
    },
  },
  created() {
    this.getData();
  },
};
</script>
