<style scoped>
.cp-card {
	width: 100%;
	max-width: 50em;
}
</style>

<template>
	<div class="d-flex flex-column justify-content-center align-items-center mt-4">
		<b-card v-if="showChangePasswordForm" class="cp-card">
			<form v-on:submit.prevent>
				<b-row class="mt-2 mb-4">
					<b-col>
						<h3>Change Password:</h3>
					</b-col>
				</b-row>
				<b-row class="mt-2">
					<b-col>
						<div class="form-floating mb-20px">
							<input type="password" class="form-control fs-13px h-45px" :class="{'is-invalid': v$.form.currentPassword.$error}" placeholder="Current Password" id="currentPassword" v-model="form.currentPassword" />
							<label for="currentPassword" class="d-flex align-items-center text-gray-600 fs-13px">Current Password</label>
						</div>
					</b-col>
				</b-row>
				<b-row class="mt-2">
					<b-col>
						<b-alert style="font-size:14px;" show variant="info">
							Password Requirements:
							<ul style="list-style-type:none;">
								<li><i :class="v$.form.newPassword.minLength.$invalid || v$.form.newPassword.required.$invalid ? iconClasses.fail : iconClasses.pass" /> Minimum 12 characters</li>
								<li><i :class="v$.form.newPassword.includesOneLowercase.$invalid ? iconClasses.fail : iconClasses.pass" /> At least one lowercase letter</li>
								<li><i :class="v$.form.newPassword.includesOneUppercase.$invalid ? iconClasses.fail : iconClasses.pass" /> At least one uppercase letter</li>
								<li><i :class="v$.form.newPassword.includesOneDigit.$invalid ? iconClasses.fail : iconClasses.pass" /> At least one number</li>
								<li><i :class="v$.form.newPassword.includesOneSymbol.$invalid ? iconClasses.fail : iconClasses.pass" /> At least one symbol</li>
							</ul>
							Confirm Password Requirement:
							<ul style="list-style-type:none;">
								<li><i :class="v$.form.confirmPassword.sameAsPassword.$invalid || v$.form.confirmPassword.required.$invalid ? iconClasses.fail : iconClasses.pass" />Matches Password</li>
							</ul>
						</b-alert>
					</b-col>
				</b-row>
				<b-row class="mt-2">
					<b-col>
						<div class="form-floating mb-20px">
							<input type="password" class="form-control fs-13px h-45px" :class="{'is-invalid': v$.form.newPassword.$error}" placeholder="New Password" id="newPassword" v-model="form.newPassword" />
							<label for="newPassword" class="d-flex align-items-center text-gray-600 fs-13px">New Password</label>
						</div>
					</b-col>
				</b-row>
				<b-row class="mt-2">
					<b-col>
						<div class="form-floating mb-20px">
							<input type="password" class="form-control fs-13px h-45px" :class="{'is-invalid': v$.form.confirmPassword.$error}" placeholder="Confirm Password" id="confirmPassword" v-model="form.confirmPassword" />
							<label for="confirmPassword" class="d-flex align-items-center text-gray-600 fs-13px">Confirm Password</label>
						</div>
					</b-col>
				</b-row>
				<b-row class="mt-2">
					<b-col>
						<button class="btn btn-success d-block w-100 h-45px btn-lg" @click="handleSubmit">
							<b-spinner v-if="loadingPostRequest" label="Spinning"></b-spinner>
							<div v-else>Submit</div>
						</button>
					</b-col>
				</b-row>
				<b-row class="mt-2">
					<b-col>
						<b-alert :show="alert.show" :variant="alert.variant" style="font-size:14px;text-align:center;margin-bottom:0px;margin-top:8px;">{{alert.message}}</b-alert>
					</b-col>
				</b-row>
			</form>
		</b-card>
		<b-card v-else class="cp-card">
			<!--This would look much nicer with some sort of success svg, like a phone with confetti or something more exciting like that-->
			<b-row class="mt-2 mb-4">
				<b-col style="text-align: center;">
					<h3>Password Reset</h3>
				</b-col>
			</b-row>
			<b-row class="mt-2 mb-4" style="text-align:center;">
				<b-col style="font-size:14px;">
					Your password has been reset successfully.
				</b-col>
			</b-row>
			<b-row class="mt-6" style="text-align:center;">
				<b-col>
					<button class="btn btn-success d-block w-100 h-45px btn-lg" @click="handleGoHome">Go Home</button>
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";

const includesOneLowercase = (value) => {
	return value.toUpperCase() != value;
};

const includesOneUppercase = (value) => {
	return value.toLowerCase() != value;
};

const includesOneDigit = (value) => {
	const isValid = /\d/.test(value);
	return isValid;
};

const includesOneSymbol = (value) => {
	const isValid = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value);
	return isValid;
};

export default {
	name: "ChangePassword",
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			loading: true,
			loadingPostRequest: false,
			showChangePasswordForm: true,
			iconClasses: {
				pass: "fas fa-check-circle text-success mx-2",
				fail: "fas fa-times-circle text-danger mx-2",
			},
			form: {
				currentPassword: "",
				newPassword: "",
				confirmPassword: "",
			},
			alert: {
				show: false,
				message: "",
				variant: "danger",
			},
		};
	},
	validations() {
		return {
			form: {
				currentPassword: {
					required,
				},
				newPassword: {
					required,
					minLength: minLength(12),
					includesOneLowercase,
					includesOneUppercase,
					includesOneDigit,
					includesOneSymbol,
				},
				confirmPassword: {
					required,
					sameAsPassword: sameAs(this.form.newPassword),
				},
			},
		};
	},
	methods: {
		async validateForm() {
			this.alert.show = false;
			const vueValidation = await this.v$.$validate();

			if (this.v$.$invalid) {
				this.alert.message = "Please fill out required fields";
				this.alert.vairant = "danger";
				this.alert.show = true;
			}

			return vueValidation;
		},
		async handleSubmit() {
			if (!this.loadingPostRequest) {
				this.loadingPostRequest = true;
				const validForm = await this.validateForm();

				if (!validForm) {
					this.alert.message = "Please fill out required fields";
					this.alert.vairant = "danger";
					this.alert.show = true;
					this.loadingPostRequest = false;
					return;
				}

				let postData = {
					password: this.form.currentPassword,
					newpassword: this.form.newPassword,
				};

				this.$http
					.post("/client/v1/user/changePassword", postData)
					.then((response) => {
						if (response.data.status === "success") {
							this.showChangePasswordForm = false;
						} else {
							this.alert.message = response.data.message.content;
							this.alert.variant = "danger";
							this.alert.show = true;
							this.loadingPostRequest = false;
						}
					})
					.catch(() => {
						this.alert.message = "Something went wrong.";
						this.alert.variant = "danger";
						this.alert.show = true;
					})
					.finally(() => {
						this.loadingPostRequest = false;
					});
			}
		},
		handleGoHome() {
			this.$router.push("/home");
		},
	},
};
</script>