<template>
  <!-- <div>
		<ol class="breadcrumb pull-right">
			<li class="breadcrumb-item"><a>Home</a></li>
			<li class="breadcrumb-item"><a>Users</a></li>
			<li class="breadcrumb-item active">
				<a>View</a>
			</li>
		</ol>
		<h1 class="page-header">User List</h1>

		<vue-good-table :columns="columns" :rows="rows" :search-options="{
                enabled: true,
                placeholder: 'Search this table',
            }" :pagination-options="{ enabled: true, position: 'top' }">
			<template slot="table-row" slot-scope="props">
				<span v-if="props.column.field == 'action'">
					<a class="btn btn-primary btn-sm" v-on:click="viewUser(props.row._id)">View</a>
				</span>
				<span v-else-if="props.column.field == 'lastLogin'">
					{{ props.formattedRow[props.column.field][props.formattedRow[props.column.field] - 1] }}
				</span>
				<span v-else>
					{{ props.formattedRow[props.column.field] }}
				</span>
			</template>
		</vue-good-table>
	</div> -->
  <div>
    <div class="row">
      <!-- begin col-6 -->
      <div class="col-xl-12">
        <!-- begin panel -->
        <panel title="User List">
          <div class="panel-body">
            <list-skeleton v-if="loadingGetRequest" />
            <vue-good-table
              v-else
              :columns="columns"
              :rows="rows"
              :pagination-options="{
                enabled: true,
                perPage: numberOfRowsDisplayed,
              }"
            >
              <template slot="pagination-bottom" slot-scope="props">
                <custom-pagination
                  :total="props.total"
                  :pageChanged="props.pageChanged"
                  :perPageChanged="props.perPageChanged"
                >
                </custom-pagination>
              </template>
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                  <a
                    class="btn btn-primary btn-sm"
                    v-on:click="viewUser(props.row._id)"
                    >View</a
                  >
                </span>
                <span v-else-if="props.column.field == 'lastLogin'">
                  {{
                    props.formattedRow[props.column.field][
                      props.formattedRow[props.column.field] - 1
                    ]
                  }}
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </panel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomPagination from "./../../components/CustomPagination.vue";
import ListSkeleton from "../../components/ListSkeleton.vue";

export default {
  name: "UserList",
  components: {
    CustomPagination,
    ListSkeleton,
  },
  data() {
    return {
      columns: [
        {
          label: "First Name",
          field: "firstName",
        },
        {
          label: "Last Name",
          field: "lastName",
        },
        {
          label: "Email Address",
          field: "emailAddress",
        },
        {
          label: "Last Login",
          field: "lastLogin",
        },
        {
          label: "Disabled",
          field: "disabled",
        },
        {
          label: "",
          field: "action",
        },
      ],
      rows: [],
      loadingGetRequest: true,
    };
  },
  computed: {
    ...mapGetters(["numberOfRowsDisplayed"]),
  },
  methods: {
    viewUser(id) {
      this.$router.push(`/user/view/${id}`);
    },
    getUsers() {
      this.$http
        .get(`/client/v1/user/subuser`)
        .then((response) => {
          this.rows = response.data.users;
        })
        .finally(() => {
          this.loadingGetRequest = false;
        });
    },
  },
  created() {
    this.getUsers();
  },
};
</script>
